<template>
  <Layout>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      <div slot="right" class="columns is-1 is-variable has-text-centered">
        <div class="column">
          <b-button
            @click="downloadDeliveryPdf"
            type="is-primary"
            :loading="isLoading"
            >Descargar Rotulado</b-button
          >
        </div>
        <div class="column">
          <b-button
            @click="downloadDeliveryCsv"
            type="is-primary"
            outlined
            :loading="isLoading"
            >Descargar Excel</b-button
          >
        </div>
      </div>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <div class="columns">
          <b-field label="Local" class="column">
            <b-select
              placeholder="Elige una opción"
              expanded
              v-model="query.local"
              @input="filterDeliveryList"
            >
              <option
                v-for="localItem in localList"
                :key="localItem.id"
                :value="localItem.id"
              >
                {{ localItem.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Estado" class="column">
            <b-select
              placeholder="Elige una opción"
              expanded
              v-model="query.status"
              @input="filterDeliveryList"
            >
              <option :value="null">Todos</option>
              <option v-for="s in status" :key="s.id" :value="s.id">
                {{ s.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Buscar" class="column">
            <b-input
              placeholder="Buscar"
              v-model="query.search"
              @keyup.native="search"
            />
          </b-field>
        </div>
      </card-component>
      <card-component>
        <b-table
          :loading="isLoading"
          hoverable
          striped
          checkable
          :data="results"
          :header-checkable="false"
          :checked-rows.sync="checkedRows"
          :is-row-checkable="
            (row) => row.status === 'CF' || row.status === 'EN'
          "
        >
          <b-table-column
            label="N° Pedido"
            field="code"
            sortable
            v-slot="props"
          >
            <router-link
              class="has-text-weight-medium has-text-primary"
              :to="{ name: 'delivery-detail', params: { id: props.row.id } }"
            >
              {{ props.row.code }}
            </router-link>
          </b-table-column>
          <b-table-column label="Local" field="local.name" v-slot="props">
            {{ props.row.order.local.name }}
          </b-table-column>
          <b-table-column label="Fecha" field="order.creation" v-slot="props">
            {{ props.row.order.creation }}
          </b-table-column>
          <b-table-column label="Nombre" field="full_name" v-slot="props">
            {{ props.row.full_name }}
          </b-table-column>
          <b-table-column label="Teléfono" field="telephone" v-slot="props">
            {{ props.row.telephone }}
          </b-table-column>
          <b-table-column
            label="N° Documento"
            field="order.num_document"
            v-slot="props"
          >
            {{ props.row.order.num_document }}
          </b-table-column>
          <b-table-column label="Monto Pagado" field="total" v-slot="props">
            S/. {{ props.row.order.total }}
          </b-table-column>
          <b-table-column label="Estado" field="status" v-slot="props">
            <b-tag :type="getStatusClass(props.row.status)">
              {{ getStatus(props.row.status) }}
            </b-tag>
          </b-table-column>
          <loading-table slot="empty" :isLoading="isLoading" />
        </b-table>
        <Pages
          :links="links"
          :pages="pages"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Pages>
      </card-component>
    </section>
  </Layout>
</template>

<script>
import { mapState } from "vuex";
import TitleBar from "@/components/TitleBar";
import HeroBar from "@/components/HeroBar";
import Layout from "@/components/Layout";
import DeliveryService from "@/services/DeliveryService";
import Pages from "@/components/Pages";
import CardComponent from "@/components/CardComponent.vue";
import LoadingTable from "@/components/Base/LoadingTable";
export default {
  name: "Error",
  components: {
    TitleBar,
    HeroBar,
    Layout,
    Pages,
    CardComponent,
    LoadingTable,
  },
  data() {
    return {
      isLoading: false,
      results: [],
      links: {},
      pages: {},
      checkedRows: [],
      query: {
        local: this.$localStorage.get("local"),
        status: null,
        search: "",
      },
      status: [
        { id: "PE", name: "Pendiente" },
        { id: "CF", name: "Confirmado" },
        { id: "EN", name: "Enviado" },
        { id: "CP", name: "Completado" },
        { id: "CN", name: "Cancelado" },
      ],
    };
  },
  computed: {
    titleStack() {
      return ["Inicio", "Delivery"];
    },
    ...mapState(["localList"]),
  },
  created() {
    this.getDeliveryList({ ...this.query });
  },
  methods: {
    errorMessage(message) {
      this.$buefy.toast.open({
        message: message ?? "Ocurrió un error",
        type: "is-danger",
      });
    },
    getStatus(status) {
      switch (status) {
        case "PE":
          return "Pendiente";
        case "CF":
          return "Confirmado";
        case "EN":
          return "Enviado";
        case "CP":
          return "Completado";
        case "CN":
          return "Cancelado";
        default:
          return "";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "PE":
          return "is-warning";
        case "CF":
          return "is-info is-light";
        case "EN":
          return "is-info";
        case "CP":
          return "is-primary";
        case "CN":
          return "is-danger";
        default:
          return "";
      }
    },
    async getDeliveryList(params) {
      try {
        this.isLoading = true;
        const response = await DeliveryService.getDeliveryList(params);
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.errorMessage();
      }
      this.isLoading = false;
    },
    search() {
      if (this.query.search != null) {
        if (this.query.search.length >= 3) {
          this.filterDeliveryList();
        } else if (this.query.search.length === 0) {
          this.query.search = null;
          this.filterDeliveryList();
        }
      }
    },
    filterDeliveryList() {
      this.getDeliveryList({ ...this.query });
      this.saveLocal();
    },
    setLink() {
      this.getDeliveryList({});
    },
    setPage(page) {
      this.getDeliveryList({ ...this.query, page: page });
    },
    async downloadDeliveryPdf() {
      if (this.checkedRows.length === 0) {
        this.errorMessage("Seleccione uno o varios elementos para descargar.");
        return;
      }
      try {
        this.isLoading = true;
        const idList = this.checkedRows.map((item) => item.id);
        const file_name = this.checkedRows.map((item) => `${item.code}-${item.order.product_variant_name}`)
        const response = await DeliveryService.downloadPdf(idList);
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        const fecha = new Date();
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `${file_name[0]}-${fecha.getDate()}/${
            fecha.getMonth() + 1}.pdf`
        );
        document.body.appendChild(fileLink);
        fileLink.click();

        this.checkedRows = [];
      } catch (error) {
        this.errorMessage();
      }
      this.isLoading = false;
    },
    async downloadDeliveryCsv() {
      try {
        this.isLoading = true;
        const response = await DeliveryService.downloadCsv({
          local: this.query.local,
        });
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        const fecha = new Date();
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Order-Delivery-${fecha.getDate()}/${
            fecha.getMonth() + 1
          }/${fecha.getFullYear()}.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (error) {
        this.errorMessage();
      }
      this.isLoading = false;
    },
    saveLocal() {
      this.$store.dispatch("saveLocal", this.query.local);
    },
  },
};
</script>
