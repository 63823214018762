import axios from 'axios'
class DeliveryService {
  getDeliveryList (params = {}) {
    const response = axios.get('orders/delivery/', {
      params
    })
    return response
  }

  getDeliveryDetail (id) {
    const response = axios.get(`orders/delivery/${id}/`)
    return response
  }

  postChangeStatus (id, data = {}) {
    const response = axios.post(`orders/delivery/${id}/change-status/`, data)
    return response
  }

  postOrderCancelled (id) {
    const response = axios.post(`orders/delivery/${id}/cancelled/`)
    return response
  }

  downloadPdf (data) {
    const response = axios({
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      url: 'order/delivery-shipping-pdf/',
      responseType: 'blob',
      data: {
        id_list: data
      }
    })
    return response
  }

  downloadCsv (params) {
    const response = axios({
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      url: 'order/delivery-shipping-csv/',
      params,
      responseType: 'blob'
    })
    return response
  }
}

const instance = new DeliveryService()
export default instance
