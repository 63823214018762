<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <hero-bar>
      Pedido #{{ results.code }}
      <div slot="right" class="columns is-1 is-variable has-text-centered">
        <div class="column" v-if="results.status != 'CP' && results.status !='CN'">
          <b-button type="is-primary" :loading="isLoading" @click.native="sendData">
            {{ textButton }}
          </b-button>
        </div>
        <div class="column" v-if="results.status == 'PE'">
          <b-button type="is-danger" :loading="isLoading" @click.native="modalCancel = true">
            Cancelar
          </b-button>
        </div>
        <div class="column">
          <router-link to="/delivery" class="button is-dark">
            Regresar
          </router-link>
        </div>
      </div>
    </hero-bar>
    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced">
        <b-steps
            v-model="currentStep"
            :rounded="true"
            :has-navigation="false"
            class="py-1"
        >
          <b-step-item step="1" :label="results.status == 'CN' ? 'Cancelado' : 'Pendiente'" :clickable="false">
          </b-step-item>
          <b-step-item step="2" label="Confirmado" :clickable="false">
          </b-step-item>
          <b-step-item step="3" :visible="true" label="Enviado" :clickable="false">
          </b-step-item>
          <b-step-item :step="true ? '4' : '3'" label="Completado" :clickable="false">
          </b-step-item>
        </b-steps>
      </card-component>
      <div class="columns tile is-parent" style="gap: 1.5rem">
        <card-component title="Cliente" icon="account" class="column is-two-fifths">
          <b-field label="Nombre" v-if="results.full_name">
            <p>{{ results.full_name }}</p>
          </b-field>
          <b-field label="N° Documento">
            <p>{{ results.order.num_document }}</p>
          </b-field>
          <b-field label="E-mail">
            <p>{{ results.email }}</p>
          </b-field>
          <b-field label="Teléfono">
            <p>{{ results.telephone }}</p>
          </b-field>

          <b-field label="Departamento" v-if="results.department">
            <p>{{ results.department }}</p>
          </b-field>
          <b-field label="Provincia" v-if="results.province">
            <p>{{ results.province }}</p>
          </b-field>
          <b-field label="Distrito" v-if="results.district">
            <p>{{ results.district }}</p>
          </b-field>

          <b-field label="Dirección">
            <p>{{ results.address }}</p>
          </b-field>
          <b-field label="Referencia">
            <p>{{ results.reference }}</p>
          </b-field>
          <b-field label="Número de Casa">
            <p>{{ results.nro }}</p>
          </b-field>
          <b-field label="Departamento" v-if="results.dpto">
            <p>{{ results.dpto }}</p>
          </b-field>
          <b-field label="Mapa" v-if="google_api_key">
            <img class="card" :src="'https://maps.googleapis.com/maps/api/staticmap?center='+results.lat+','+results.lng+'&zoom=16&size=400x300&markers=color:red%7Clabel:C%7C'+results.lat+','+results.lng+'&key='+google_api_key" />
          </b-field>
        </card-component>
        <card-component
          title="Producto"
          icon="archive"
          class="column"
        >
          <div class="has-text-centered">
            <img class="card" :src="results.order.product_variant_image" alt="Imagen del producto">
          </div>
          <b-field :label="results.order.product_variant_name">
            <div class="delivery-product" v-html="results.order.product_variant_description"></div>
          </b-field>
          <div v-if="results.note">
            <hr />
            <b-field label="Nota">
              <p>{{ results.note }}</p>
            </b-field>
          </div>
          <hr />
          <b-field horizontal label="Subtotal">
            <p>S/. {{ results.order.price }}</p>
          </b-field>
          <b-field horizontal label="Descuento">
            <p>S/. {{ results.order.discount }}</p>
          </b-field>
          <b-field horizontal label="IGV">
            <p>S/. {{ results.order.igv }}</p>
          </b-field>
          <b-field horizontal label="Total Pagado">
            <p>S/. {{ results.order.total }}</p>
          </b-field>
        </card-component>
      </div>
    </section>
    <ModalConfirm
      v-if="modalConfirm"
      :currentDate="currentDate"
      @hidden="modalConfirm = false"
      @reload="getDeliveryDetail"
    />
    <ModalCancel
      v-if="modalCancel"
      @hidden="modalCancel = false"
    />
  </Layout>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Layout from '@/components/Layout'
import DeliveryService from '@/services/DeliveryService'
import CardComponent from '@/components/CardComponent'
import ModalConfirm from '@/components/Delivery/ModalConfirm'
import ModalCancel from '@/components/Delivery/ModalCancel'
export default {
  name: 'Error',
  components: {
    HeroBar,
    TitleBar,
    Layout,
    CardComponent,
    ModalConfirm,
    ModalCancel
  },
  data () {
    return {
      currentStep: 0,
      isLoading: false,
      google_api_key: null,
      textButton: 'Confirmar',
      currentDate: '',
      modalConfirm: false,
      modalCancel: false,
      results: {
        code: '',
        status: 'CP',
        email: '',
        address: '',
        nro: '',
        dpto: '',
        telephone: '',
        order: {
          product_variant_description: '',
          product_variant_name: '',
          product_variant_image: ''
        }
      },
      status: [
        { id: 'PE', name: 'Pendiente' },
        { id: 'CF', name: 'Confirmado' },
        { id: 'EN', name: 'Enviado' },
        { id: 'CP', name: 'Completado' },
        { id: 'CN', name: 'Cancelado' }
      ]
    }
  },
  computed: {
    titleStack () {
      return ['Inicio', 'Delivery', this.$route.params.id]
    }
  },
  created () {
    this.getDeliveryDetail()
  },
  mounted () {
    this.google_api_key = 'AIzaSyBN7v9L9OiTGFib9o67NzSw1IVDH8UKOaE'
  },
  methods: {
    errorMessage () {
      this.$buefy.toast.open({
        message: 'Ocurrió un error.',
        type: 'is-danger'
      })
    },
    getStatusStep (status) {
      switch (status) {
        case 'PE':
          this.currentStep = 0
          this.textButton = 'Confirmar'
          break
        case 'CF':
          this.currentStep = 1
          this.textButton = 'Enviar'
          break
        case 'EN':
          this.currentStep = 2
          this.textButton = 'Completar'
          break
        case 'CP': this.currentStep = 3
          break
        default: this.currentStep = 0
      }
    },
    async getDeliveryDetail () {
      try {
        this.isLoading = true
        const response = await DeliveryService.getDeliveryDetail(this.$route.params.id)
        this.results = response.data
        const splitDate = response.data.today.split('-')
        this.currentDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2])
        this.getStatusStep(response.data.status)
      } catch (error) {
        this.errorMessage()
        this.$router.push({ name: 'delivery' })
      }
      this.isLoading = false
    },
    async sendData () {
      if (this.results.status === 'PE') {
        this.modalConfirm = true
      } else {
        try {
          this.isLoading = true
          await DeliveryService.postChangeStatus(this.$route.params.id)
          this.getDeliveryDetail()
        } catch (error) {
          this.errorMessage()
        }
        this.isLoading = false
      }
    }
  }
}
</script>
<style lang="scss">
// Style steps - Buefy
.b-steps {
  .step-marker {
    height: 5rem !important;
    width: 5rem !important;
  }
  .step-item::before,
  .step-item::after {
    top: 2.5rem !important;
  }
  .is-active {
    .step-marker {
      background-color: #00b16c !important;
      border-color: white !important;
      color: white !important;
    }
  }
  .step-content {
    display: none !important;
  }
}
.delivery-product {
  li {
    list-style: disc inside;
  }
}
</style>
