<template>
  <b-modal v-model="modalConfirm" :width="468">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <h3 class="modal-card-title is-size-4 has-text-weight-medium">
          Confirmar Pedido
        </h3>
        <button
          type="button"
          class="delete"
          @click="modalConfirm = false"
        />
      </header>
      <section class="modal-card-body">
        <b-field
          label="Fecha"
          :type="{ 'is-danger': $v.date.$error }"
          :message="[
            {
              'Requerido. Selecciona una fecha':
                !$v.date.required && $v.date.$error
            }
          ]"
        >
          <b-datepicker
            v-model="date"
            placeholder="Elige una fecha"
            :append-to-body="true"
            :min-date="currentDate"
            @input="validateDate"
          >
          </b-datepicker>
        </b-field>
        <b-field label="Horario" v-if="type_date">
          <b-select
            v-if="type_date == 'today'"
            placeholder="Elige una opción"
            v-model="form.arrive_minute"
            expanded
          >
            <option
              v-for="item in arrive_minutes"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</option>
          </b-select>
          <b-select
            v-if="type_date == 'out_day'"
            placeholder="Elige una opción"
            v-model="form.arrive_hour"
            expanded
          >
            <option
              v-for="item in arrive_hours"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button
          label="Guardar"
          type="is-primary"
          :loading="isLoading"
          @click="postChangeStatus"
        />
        <b-button
          label="Cerrar"
          type="is-dark"
          @click="modalConfirm = false"
        />
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DeliveryService from '@/services/DeliveryService'
import formatDate from '@/mixins/formatDate'
export default {
  props: {
    currentDate: Date
  },
  mixins: [formatDate],
  data () {
    return {
      modalConfirm: true,
      isLoading: false,
      date: [],
      type_date: '',
      form: {
        arrive_date: '',
        arrive_hour: '',
        arrive_minute: '',
        today: null
      },
      arrive_minutes: [
        { value: 1, name: '30 min' },
        { value: 2, name: '45 min' },
        { value: 3, name: '1 hora' },
        { value: 4, name: '1 hora 15 min' },
        { value: 5, name: '1 hora 30 min' },
        { value: 6, name: '2 horas ' }
      ],
      arrive_hours: [
        { value: 1, name: '9 am - 9 pm' }
      ]
    }
  },
  validations: {
    date: { required }
  },
  watch: {
    modalConfirm () {
      this.$emit('hidden')
    }
  },
  methods: {
    async postChangeStatus () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true
          await DeliveryService.postChangeStatus(
            this.$route.params.id,
            this.form
          )
          await this.$emit('reload')
          this.modalConfirm = false
        } catch (error) {
          this.isLoading = false
          const message = 'Selecciona un horario de entrega'
          this.$buefy.toast.open({
            message,
            type: 'is-danger'
          })
        }
      }
    },
    validateDate () {
      this.form.arrive_minute = ''
      this.form.arrive_hour = ''
      this.form.arrive_date = this.formatDateReverse(this.date)
      if (this.form.arrive_date === this.formatDateReverse(this.currentDate)) {
        this.type_date = 'today'
        this.form.today = true
      } else {
        this.type_date = 'out_day'
        this.form.today = false
      }
    }
  }
}
</script>
<style lang="scss">
  .control.datepicker {
    z-index: 99 !important;
  }
</style>
