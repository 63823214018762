<template>
  <b-modal v-model="modalCancel" :width="468">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <h3 class="modal-card-title is-size-4 has-text-weight-medium">
          Cancelar Pedido
        </h3>
        <button
          type="button"
          class="delete"
          @click="modalCancel = false"
        />
      </header>
      <footer class="modal-card-foot">
        <b-button
          label="Sí, Cancelar"
          type="is-danger"
          :loading="isLoading"
          @click="postOrderCancelled"
        />
        <b-button
          label="Cerrar"
          type="is-dark"
          @click="modalCancel = false"
        />
      </footer>
    </div>
  </b-modal>
</template>

<script>
import DeliveryService from '@/services/DeliveryService'
export default {
  props: {
    currentDate: Date
  },
  data () {
    return {
      modalCancel: true,
      isLoading: false
    }
  },
  watch: {
    modalCancel () {
      this.$emit('hidden')
    }
  },
  methods: {
    async postOrderCancelled () {
      try {
        this.isLoading = true
        await DeliveryService.postOrderCancelled(this.$route.params.id)
        this.$router.push({ name: 'delivery' })
      } catch (error) {
        this.isLoading = false
        this.$buefy.toast.open({
          message: 'Ocurrió un error.',
          type: 'is-danger'
        })
      }
    }
  }
}
</script>
